import { FC } from 'react'
import { useController, UseControllerProps } from 'local-react-hook-form'
import { TextField } from './TextField/TextField'
import { TextFieldPropsType } from './TextField/TextFieldPropsType'

type FormTextFieldProps = UseControllerProps<any> & TextFieldPropsType

export const FormTextField: FC<FormTextFieldProps> = ({
  rules,
  control,
  name,
  shouldUnregister,
  defaultValue,
  helpText,
  ...restOfProps
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  })

  return (
    <TextField
      id={name}
      helpText={helpText || error?.message}
      invalid={invalid}
      {...field}
      {...restOfProps}
    />
  )
}
