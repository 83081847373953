import { Close } from '@aether/icons'
import { Media, Modal } from '@aether/components'
import { MediaFieldType } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import { useTranslation } from 'react-i18next'

const TechnicalFeatures = styled('div', {
  display: 'grid',
})

const FeaturesWrapSlider = styled('div', {
  marginTop: '$12',
  display: 'flex',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  width: '100%',
  '@lg': {
    overflow: 'hidden',
  },
})

const ShowTechnicalFeaturesModal = styled('div', {
  position: 'relative',
  maxWidth: '690px',
  padding: '$24 $24 $64',

  '@md': {
    minWidth: '690px',
    padding: '$48 50px $64 $48',
  },
})

const StyledCloseModal = styled('button', {
  background: 'transparent',
  position: 'absolute',
  top: '$20',
  right: '$16',
  $focusStyle: 'default',
})

const TechnicalFeaturesModalHeading = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: '$12',
  padding: '0',
  marginBottom: '$12',
  width: '100%',

  '@md': {
    columnGap: '$16',
    marginBottom: '$24',
  },

  variants: {
    isMobile: {
      true: {
        display: 'none',
        '@md': {
          display: 'flex',
        },
      },
    },
  },
})

const TechnicalFeaturesModalDescription = styled('div', {
  $aetherFont: 'body02',
  textTransform: 'none',
  textAlign: 'left',
  width: '100%',
})

const TechnicalFeaturesModalFeature = styled('div', {
  width: '100%',
  $aetherFont: 'ui03',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'left',
  '& svg': {
    display: 'block',
    margin: '0 auto',
  },
})

const TechnicalFeaturesModalTitle = styled('h1', {
  $aetherFont: 'heading05',
  $containerSpace: 'none',
  textTransform: 'none',
})

const TechnicalFeaturesFeature = styled('button', {
  $focusStyle: 'default',
  margin: 0,
  padding: 0,
  background: 'transparent',
  transition: `background ease-in 150ms`,
  borderRadius: '$r3',
  '&:hover': {
    backgroundColor: '$gray_light_third',
  },
})

const TechnicalFeaturesFeatureImage = styled('div', {
  width: 42,
  height: 42,
  margin: '0 auto',

  variants: {
    type: {
      modal: {
        width: '$72',
        height: '$72',
        padding: '$16',
        borderRadius: '$r3',
        margin: 0,
      },
    },
    selected: {
      true: {
        background: '$gray_extra_light',
      },
    },
    isMobile: {
      true: {
        '@md': {
          display: 'none',
        },
      },
    },
  },
})
const FeatureImage = styled('div', {
  width: 42,
  height: 42,
  margin: '0 auto',

  variants: {
    type: {
      modal: {
        width: '$72',
        height: '$72',
        background: '$gray_light',
        padding: '$16',
        borderRadius: '$r3',
        margin: 0,
      },
    },
    isMobile: {
      true: {
        '@md': {
          display: 'none',
        },
      },
    },
  },
})

const ModalContentForSingleItem = ({
  feature,
  ariaLabel,
}: {
  feature: {
    label: string
    image: MediaFieldType
    description: string
  }
  ariaLabel: string
}) => {
  return (
    <>
      <TechnicalFeatures>
        <FeaturesWrapSlider>
          <TechnicalFeaturesModalHeading aria-label={ariaLabel} isMobile>
            <TechnicalFeatures>
              <TechnicalFeaturesFeatureImage type={'modal'} selected>
                <Media
                  {...feature.image}
                  hardcropRatio="square"
                  sizes={{ default: '42px' }}
                />
              </TechnicalFeaturesFeatureImage>
            </TechnicalFeatures>
          </TechnicalFeaturesModalHeading>
        </FeaturesWrapSlider>
      </TechnicalFeatures>
      <TechnicalFeaturesModalFeature>
        <TechnicalFeaturesModalHeading>
          <FeatureImage type={'modal'} isMobile>
            <Media
              {...feature.image}
              hardcropRatio="square"
              sizes={{ default: '42px' }}
            />
          </FeatureImage>
          <TechnicalFeaturesModalTitle id="technical-feature-heading">
            {feature.label}
          </TechnicalFeaturesModalTitle>
        </TechnicalFeaturesModalHeading>
        <TechnicalFeaturesModalDescription>
          {feature.description}
        </TechnicalFeaturesModalDescription>
      </TechnicalFeaturesModalFeature>
    </>
  )
}

type TechnicalFeaturesModalProps = {
  showTechnicalFeaturesModal: boolean
  handleCloseTechnicalFeaturesModal: () => void
  mappedFeatures: {
    label: string
    image: MediaFieldType
    description: string
  }[]
  selectedTechnicalFeature: number
  setSelectedTechnicalFeature: (index: number) => void
}

export const TechnicalFeaturesModal = ({
  showTechnicalFeaturesModal,
  handleCloseTechnicalFeaturesModal,
  mappedFeatures,
  selectedTechnicalFeature,
  setSelectedTechnicalFeature,
}: TechnicalFeaturesModalProps) => {
  const matchesMd = useMediaQuery('md')
  const { t } = useTranslation('product')

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!mappedFeatures || mappedFeatures.length === 1) {
      return
    }

    let nextIndex = -1

    if (event.key === 'ArrowRight') {
      nextIndex = (selectedTechnicalFeature + 1) % mappedFeatures.length
    }

    if (event.key === 'ArrowLeft') {
      nextIndex =
        (selectedTechnicalFeature - 1 + mappedFeatures.length) %
        mappedFeatures.length
    }

    if (nextIndex > -1 && nextIndex < mappedFeatures.length) {
      event.preventDefault()
      setSelectedTechnicalFeature(nextIndex)
      const nextElement = event.currentTarget.children[nextIndex]

      if (nextElement instanceof HTMLButtonElement) {
        nextElement.focus()
      }
    }
  }

  return (
    <Modal
      preventScroll={true}
      isOpen={showTechnicalFeaturesModal}
      size={matchesMd ? 'intrinsic' : 'stretch-x'}
      overlayVariant={'darker'}
      onRequestClose={handleCloseTechnicalFeaturesModal}
      aria={{
        labelledby: 'technical-features-heading',
      }}
    >
      <ShowTechnicalFeaturesModal>
        <StyledCloseModal
          onClick={handleCloseTechnicalFeaturesModal}
          aria-label={String(t('closeModal'))}
        >
          <Close size="medium" />
        </StyledCloseModal>
        {mappedFeatures.length === 1 ? (
          <ModalContentForSingleItem
            feature={mappedFeatures[0]}
            ariaLabel={String(t('techFeatures'))}
          />
        ) : (
          <>
            <TechnicalFeatures>
              <FeaturesWrapSlider>
                <TechnicalFeaturesModalHeading
                  onKeyDown={handleKeyDown}
                  role="tablist"
                  aria-label={String(t('techFeatures'))}
                  aria-orientation="horizontal"
                  isMobile
                >
                  {mappedFeatures.map((feature, index) => (
                    <TechnicalFeaturesFeature
                      onClick={() => setSelectedTechnicalFeature(index)}
                      key={index}
                      role="tab"
                      aria-label={feature.label}
                      aria-selected={selectedTechnicalFeature === index}
                      tabIndex={selectedTechnicalFeature === index ? 0 : -1}
                      id={`tab-${feature.label}`}
                      aria-controls={`tabpanel-${feature.label}`}
                    >
                      <TechnicalFeaturesFeatureImage
                        type={'modal'}
                        selected={selectedTechnicalFeature === index}
                      >
                        <Media
                          {...feature.image}
                          hardcropRatio="square"
                          sizes={{ default: '42px' }}
                        />
                      </TechnicalFeaturesFeatureImage>
                    </TechnicalFeaturesFeature>
                  ))}
                </TechnicalFeaturesModalHeading>
              </FeaturesWrapSlider>
            </TechnicalFeatures>

            <TechnicalFeaturesModalFeature
              id={`tabpanel-${mappedFeatures[selectedTechnicalFeature].label}`}
              role="tabpanel"
              aria-labelledby={`tab-${mappedFeatures[selectedTechnicalFeature].label}`}
            >
              <TechnicalFeaturesModalHeading>
                <FeatureImage type={'modal'} isMobile>
                  <Media
                    {...mappedFeatures[selectedTechnicalFeature].image}
                    hardcropRatio="square"
                    sizes={{ default: '42px' }}
                  />
                </FeatureImage>
                <TechnicalFeaturesModalTitle id="technical-features-heading">
                  {mappedFeatures[selectedTechnicalFeature].label}
                </TechnicalFeaturesModalTitle>
              </TechnicalFeaturesModalHeading>
              <TechnicalFeaturesModalDescription>
                {mappedFeatures[selectedTechnicalFeature].description}
              </TechnicalFeaturesModalDescription>
            </TechnicalFeaturesModalFeature>
          </>
        )}
      </ShowTechnicalFeaturesModal>
    </Modal>
  )
}
