import { Dispatch, FC, SetStateAction, useRef } from 'react'
import { styled } from '@aether/styles'
import { RadioSwitch } from '@aether/components'
import { useTemperatureChart } from './useTemperatureChart'
import { QuestionMark } from '@aether/icons'

const Root = styled('div', {
  padding: '$8 0 0',
  display: 'grid',
  gridRowGap: '$16',
  gridTemplateRows: '1fr',
  '@md': {
    gridRowGap: '$16',
    padding: '$24 0 0 0',
  },
  variants: {
    isProductConversionArea: {
      true: {
        padding: '0',
        gridRowGap: '$8',
        '@md': {
          padding: '0',
          gridRowGap: '$16',
        },
      },
    },
  },
})

const Title = styled('h3', {
  gridRow: 1,
  gridColumn: 1,
  $aetherFont: 'heading05',
  alignSelf: 'center',

  variants: {
    isProductConversionArea: {
      true: {
        $aetherFont: 'ui01',
      },
    },
  },
})

const RadioSwitchWrap = styled('div', {
  gridRow: 1,
  gridColumn: '2/2',
  justifyContent: 'end',
  display: 'flex',
  alignItems: 'center',
  justifySelf: 'end',
  width: '100%',
  zIndex: '$content',
  '@md': {
    gridRow: 1,
  },
})

const IconPlaceholder = styled('div', {
  width: '15px',
  height: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '9999px',
  background: '$black_light',

  variants: {
    isHover: {
      true: {
        transition: 'all 300ms ease',
        '&:hover': {
          cursor: 'pointer',
          background: '$black',
          '& > svg > path': {
            fill: '$white',
          },
        },
      },
    },
  },
})

const TitleWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: '$8',
})

const Bar = styled('div', {
  width: '100%',
  height: '12px',
  borderRadius: '999px',
  display: 'grid',
  variants: {
    isProductConversionArea: {
      true: {
        marginTop: '$8',
        background: '$gray_light_third',
      },
      false: {
        marginTop: '$24',
        background: '$black_light',
      },
    },
    unit: {
      C: {
        gridTemplateColumns: 'repeat(50, 1fr)',
      },
      F: {
        gridTemplateColumns: 'repeat(80, 1fr)',
      },
    },
  },
})

const TemperatureRangeSection = styled('div', {
  variants: {
    isProductConversionArea: {
      true: {
        background:
          'linear-gradient(90deg, $gray_light_third 0%, $black 49.5%, $gray_light_third 99%)',
      },
      false: {
        background:
          'linear-gradient(90deg, transparent 0%, $black 49.5%, transparent 99%)',
      },
    },
    isStartScale: {
      true: {},
      false: {},
    },
    isEndScale: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      isProductConversionArea: true,
      isStartScale: true,
      css: {
        borderRadius: '$r1 0 0 $r1',
        background:
          'linear-gradient(90deg, $black 0%, $black 49.5%, $gray_light_third 99%)',
      },
    },
    {
      isProductConversionArea: false,
      isStartScale: true,
      css: {
        borderRadius: '$r1 0 0 $r1',
        background:
          'linear-gradient(90deg, $black 0%, $black 49.5%, transparent 99%)',
      },
    },
    {
      isProductConversionArea: true,
      isEndScale: true,
      css: {
        borderRadius: '$r1 0 0 $r1',
        background:
          'linear-gradient(90deg, $gray_light_third 0%, $black 49.5%, $black 99%)',
      },
    },
    {
      isProductConversionArea: false,
      isEndScale: true,
      css: {
        background:
          'linear-gradient(90deg, transparent 0%, $black 49.5%, $black 99%)',
      },
    },
  ],
})

const Scale = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '$20',
  marginTop: '$8',
  '& div': {
    textAlign: 'center',
    fontSize: '11px',
    color: '#000',
  },
})

const HiddenDescription = styled('p', {
  $visuallyHidden: true,
})

const ScaleValue = styled('div', {
  $aetherFont: 'ui04',
})

const MIN_CELSIUS_VALUE = -20
const MAX_CELSIUS_VALUE = 30
const MIN_FAHRENHEIT_VALUE = 0
const MAX_FAHRENHEIT_VALUE = 80
const STEP = 10

const generateScaleValues = (
  min: number,
  max: number,
  step: number,
  unit: 'C' | 'F',
) => {
  const values = []
  if (unit === 'F') {
    const specificValues = [0, 20, 40, 60, 80] // values to show
    for (let i = min; i <= max; i += step) {
      if (specificValues.includes(i)) {
        values.push(i)
      } else {
        values.push('')
      }
    }
  } else {
    for (let i = min; i <= max; i += step) {
      values.push(i)
    }
  }
  return values
}

const calculateGridColumn = (value: number, unit: 'C' | 'F') => {
  if (unit === 'C') {
    return Math.round((value + 24) * (50 / 51)) // -25 to 30 range
  } else {
    return Math.round(value + (value < 0 ? 11 : 1)) // 0 to 80 range
  }
}

const calculateMinPosition = (value: number, unit: 'C' | 'F') => {
  if (unit === 'C') {
    return Math.max(1, calculateGridColumn(value, unit) - 3)
  } else {
    return Math.max(1, calculateGridColumn(value, unit) - 5)
  }
}

const calculateMaxPosition = (value: number, unit: 'C' | 'F') => {
  if (unit === 'C') {
    return Math.min(50, calculateGridColumn(value, unit) + 3)
  } else {
    return Math.min(80, calculateGridColumn(value, unit) + 5)
  }
}

type Props = {
  selectedMinTemp: number
  selectedMaxTemp: number
  className?: string
  title?: string
  hiddenDescriptionF?: string
  hiddenDescriptionC?: string
  isProductConversionArea?: boolean
  id: string
  unit: 'C' | 'F'
  setUnit?: Dispatch<SetStateAction<'C' | 'F'>>
}

export const TemperatureRangeTechSection: FC<Props> = ({
  className,
  selectedMinTemp,
  selectedMaxTemp,
  title,
  hiddenDescriptionF,
  hiddenDescriptionC,
  isProductConversionArea = false,
  id,
  unit,
  setUnit,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const unitSymbol = unit === 'C' ? '°C' : '°F'

  const { minTempConverted, maxTempConverted } = useTemperatureChart({
    chartRef,
    minTemp: selectedMinTemp,
    maxTemp: selectedMaxTemp,
    unit,
  })

  const scaleValues =
    unit === 'C'
      ? generateScaleValues(MIN_CELSIUS_VALUE, MAX_CELSIUS_VALUE, STEP, unit)
      : generateScaleValues(
          MIN_FAHRENHEIT_VALUE,
          MAX_FAHRENHEIT_VALUE,
          20,
          unit,
        )

  return (
    <>
      <Root
        isProductConversionArea={isProductConversionArea}
        className={className}
        ref={chartRef}
      >
        <TitleWrapper>
          <Title isProductConversionArea={isProductConversionArea}>
            {title}
          </Title>
          {isProductConversionArea && (
            <IconPlaceholder isHover>
              <QuestionMark fill="currentColor" />
            </IconPlaceholder>
          )}
        </TitleWrapper>
        <RadioSwitchWrap onClick={(e) => e.stopPropagation()}>
          <RadioSwitch
            items={[
              { label: '°F ', value: 'F' },
              { label: '°C ', value: 'C' },
            ]}
            value={unit}
            onChange={(newValue: string) => {
              if (newValue === 'F' || newValue === 'C') {
                setUnit?.(newValue)
              }
            }}
            type="outline"
            name={`temperature-unit-selector-${id}`}
          />
        </RadioSwitchWrap>
      </Root>
      <Bar unit={unit} isProductConversionArea={isProductConversionArea}>
        <TemperatureRangeSection
          isProductConversionArea={isProductConversionArea}
          isStartScale={calculateGridColumn(minTempConverted, unit) < 2}
          isEndScale={calculateGridColumn(maxTempConverted, unit) > 75}
          css={{
            gridColumn: `${calculateMinPosition(
              minTempConverted,
              unit,
            )} / ${calculateMaxPosition(maxTempConverted, unit)}`,
          }}
        ></TemperatureRangeSection>
      </Bar>
      <Scale>
        {scaleValues.map((value, index) => (
          <ScaleValue key={index}>
            {value}
            {typeof value === 'number' && unitSymbol}
          </ScaleValue>
        ))}
      </Scale>
      {(hiddenDescriptionF || hiddenDescriptionC) && (
        <HiddenDescription>
          {unit === 'C' ? hiddenDescriptionC : hiddenDescriptionF}
        </HiddenDescription>
      )}
    </>
  )
}
