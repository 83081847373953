import { PasswordModuleProps } from '@aether/models'
import { styled } from '@aether/styles'
import React, { FormEventHandler, useEffect, useState } from 'react'
import { TextField } from '@aether/forms'
import { useRouter } from 'next/router'
import { Media, Button } from '@aether/components'
import { useTranslation } from 'react-i18next'

const PasswordWrapper = styled('section', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  minHeight: 'calc(100vh - $headerHeightLarge)',
  gap: '$24',
  justifyContent: 'center',
  padding: '$8 $24 $56',
  '@md': {
    gap: '$72',
    alignItems: 'center',
    padding: '$48 10% $96',
    gridTemplateColumns: '1fr 1fr',
  },
})
const ImageWrapper = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const PasswordFormWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$16',
  '@md': {
    gap: '$24',
  },
})
const PasswordHeading = styled('h1', {
  $aetherFont: 'heading03',
})
const PasswordForm = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$16',
})

const PasswordError = styled('p', {
  color: '$red',
})
const STORAGE_KEY = 'aether-passwords'
type Props = {
  /**
   * Sanity ID of the document containing the password module
   */
  documentId: string
  /**
   * Password module data
   */
  data?: PasswordModuleProps
  children: React.ReactNode
}
/**
 * Set this to true to show password prompt every time instead of auto-verifying if a password is stored in local storage
 */
const DEBUG_SKIP_PASSWORD_AUTO = false

/**
 * This component is used to show a password prompt if the page is password protected.
 *
 * @usage
 * <PasswordProtected documentId={_id} data={passwordModule}>
 *   {page content to protect}
 * </PasswordProtected>
 */
export const PasswordProtected: React.FC<Props> = ({
  documentId,
  data,
  children,
}: Props) => {
  const router = useRouter()
  const { t } = useTranslation('modules', { keyPrefix: 'passwordProtected' })
  const pathname = router.asPath.split('?')[0].split('#')[0]
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [verified, setVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setError('')
    setVerified(false)
    const storedPasswords = JSON.parse(
      localStorage.getItem(STORAGE_KEY) ?? '{}',
    )
    const storedPassword = storedPasswords[pathname] ?? null
    if (storedPassword && !DEBUG_SKIP_PASSWORD_AUTO) {
      setPassword(storedPassword)
      verifyPassword(storedPassword)
    } else {
      setPassword('')
    }
  }, [pathname])

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    verifyPassword(password)
  }
  const verifyPassword = async (password: string) => {
    if (!documentId) {
      return
    }
    setLoading(true)
    try {
      const response = await fetch(`/api/auth/verify-password`, {
        method: 'POST',
        body: JSON.stringify({
          documentId,
          password,
        }),
      })
      const data = await response.json()
      if (data.error) {
        setError(data.error)
        setVerified(false)
      } else if (data.success) {
        setError('')
        setVerified(true)
        const storedPasswords = JSON.parse(
          localStorage.getItem(STORAGE_KEY) ?? '{}',
        )
        storedPasswords[pathname] = password
        localStorage.setItem(STORAGE_KEY, JSON.stringify(storedPasswords))
      }
    } catch (error) {
      setError('An error occurred, try again later')
      setVerified(false)
    } finally {
      setLoading(false)
    }
  }
  if (!data || !data.isPasswordProtected || verified) {
    return <>{children}</>
  }
  return (
    <PasswordWrapper>
      <ImageWrapper>
        <Media {...data.media} sizes={{ md: '50vw', default: '100vw' }} />
      </ImageWrapper>
      <PasswordFormWrapper>
        <PasswordHeading>{data.title}</PasswordHeading>
        <PasswordForm onSubmit={onSubmit}>
          <TextField
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label={t('passwordFieldLabel')}
            invalid={!!error}
          />
          {error && <PasswordError>{error}</PasswordError>}
          <Button
            type="submit"
            appearance="badgeBlack"
            size="L"
            disabled={loading}
          >
            {t('submitButtonLabel')}
          </Button>
        </PasswordForm>
      </PasswordFormWrapper>
    </PasswordWrapper>
  )
}
