import { styled } from '@aether/styles'
import { forwardRef } from 'react'
import { SelectFieldPropsType } from './SelectFieldPropsType'

export const FieldContainer = styled('div', {
  width: '100%',
})

export const SelectContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  $aetherFont: 'ui01',

  '&::before': {
    pointerEvents: 'none',
    width: '9px',
    height: '9px',
    borderBottom: '1px solid $black',
    borderRight: '1px solid $black',
    position: 'absolute',
    content: '',
    right: '10%',
    transform: 'rotate(45deg)',
  },
})

export const Select = styled('select', {
  margin: 0,
  appearance: 'none',
  $aetherFont: 'ui01',
  display: 'block',
  padding: '0 32px',
  height: '$buttonHeightL',
  cursor: 'pointer',
  borderRadius: '$rMax',
  border: '1px solid',
  borderColor: '$black',
  backgroundColor: '$white',
  color: '$gray500',
  width: '100%',
  textOverflow: 'ellipsis',
  '&:focus': {
    borderColor: '$teal500',
    outline: '0',
    $inputShadow: '$colors$blue',
  },
  variants: {
    isInvalid: {
      true: {
        borderColor: '$red500',
        color: '$red500',
        '&:focus': {
          borderColor: '$red500',
          outline: 'none',
          $inputShadow: '$colors$red500',
        },
      },
    },
    isDisabled: {
      true: {
        backgroundColor: '$gray500_01',
      },
      false: {
        backgroundColor: 'white',
      },
    },
  },
})

const Label = styled('label', {
  zIndex: 2,
  margin: 0,
  border: 0,
  top: 0,
  left: 0,
  transition: 'transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  transformOrigin: 'left top',
  cursor: 'text',
  pointerEvents: 'none',
  color: '$gray',
  position: 'absolute',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  backgroundColor: '$white',
  padding: '2px 8px',
  transform: 'translate(27px, -9px) scale(0.80)',
  variants: {
    isInvalid: {
      true: {
        color: '$red',
      },
    },
  },
})

const HelpText = styled('div', {
  position: 'relative',
  $aetherFont: 'body03',
  paddingTop: '$8',
  display: 'flex',
  alignItems: 'center',
  variants: {
    isInvalid: {
      true: {
        color: '$red',
      },
    },
  },
})

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldPropsType>(
  (props, ref) => {
    const {
      autoComplete,
      disabled,
      id,
      children,
      invalid,
      helpText,
      label,
      value,
      style,
      className,
      ...rest
    } = props

    const helpId = helpText ? `${id}__hint` : undefined

    return (
      <FieldContainer className={className}>
        <SelectContainer>
          {label && (
            <Label htmlFor={id} isInvalid={invalid}>
              {label}
            </Label>
          )}

          <Select
            {...rest}
            aria-describedby={helpId}
            aria-errormessage={invalid && helpId ? helpId : undefined}
            aria-invalid={invalid}
            disabled={disabled}
            id={id}
            ref={ref}
            autoComplete={autoComplete}
            isInvalid={invalid}
            isDisabled={disabled}
            value={value}
          >
            {children}
          </Select>
        </SelectContainer>
        {helpText ? (
          <HelpText id={helpId} isInvalid={invalid}>
            {helpText}
          </HelpText>
        ) : null}
      </FieldContainer>
    )
  },
)
