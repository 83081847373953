import { FC } from 'react'
import { SectionCollaborationsCardsProps } from '@aether/models'
import { styled } from '@aether/styles'
import { Container as BaseContainer, Link, Media } from '@aether/components'
import { useTranslation } from 'react-i18next'

const Container = styled(BaseContainer, {
  paddingBottom: '$sectionSpaceMobile',
  '@md': {
    paddingBottom: '$sectionSpaceDesktop',
  },
})
const Title = styled('h2', {
  $aetherFont: 'heading03',
  marginBottom: '$20',
  paddingTop: '$16',
  borderTop: '1px solid $black',
})

const Grid = styled('ul', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  columnGap: '$24',
  rowGap: '$32',
  listStyle: 'none',
  '@md': {
    gridTemplateColumns: '1fr 1fr',
    rowGap: '$48',
  },
})

const Card = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$12',
  height: 'fit-content',
})
const CardTitle = styled('h3', {
  $aetherFont: 'heading04',
})
export const SectionCollaborationsCards: FC<
  SectionCollaborationsCardsProps
> = ({ title, cards, priority }) => {
  const { t } = useTranslation('sectionCollaborationsCards')
  return (
    <Container>
      <Title>{title}</Title>
      <Grid>
        {cards?.map((card, index) => (
          <Card key={card._key}>
            <Link {...card.link} appearance="block" tabIndex={-1}>
              <Media
                {...card.media}
                layout="responsive"
                sizes={{ default: '500px', sm: '700px', md: '1000px' }}
                priority={priority && index < 2}
              />
            </Link>
            <Link {...card.link} appearance="block" tabIndex={-1}>
              <CardTitle>{card.title}</CardTitle>
            </Link>
            <Link
              {...card.link}
              accessibilityLabel={
                card.link?.accessibilityLabel ?? (t('explore') as string)
              }
              appearance="primary"
            >
              {card.linkText || t('explore')}
            </Link>
          </Card>
        ))}
      </Grid>
    </Container>
  )
}
