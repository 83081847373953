import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Loader } from '@aether/components'
import { styled } from '@aether/styles'

type SubmitButtonProps = {
  text?: string
  helpText?: string
  invalid?: boolean
  success?: boolean
  loading?: boolean
  disabled?: boolean
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '6px',
})

const HelpText = styled('span', {
  position: 'relative',
  $aetherFont: 'body03',
  minHeight: '22px',
  display: 'flex',
  alignItems: 'center',
  marginTop: '$4',
  variants: {
    invalid: {
      true: {
        color: '$red',
      },
    },
    success: {
      true: {
        color: '$blue',
      },
    },
  },
})

const StyledButton = styled(Button, {
  width: '100%',
  '&:focus-within': {
    outline: '$blue solid 3px',
    outlineOffset: '2px',
  },
  variants: {
    invalid: {
      true: {
        '&:focus-within': {
          outline: '$red solid 3px',
          outlineOffset: '2px',
        },
      },
    },
  },
})

export const SubmitButton: FC<SubmitButtonProps> = ({
  text,
  helpText,
  success,
  invalid,
  loading,
  disabled,
}) => {
  const { t } = useTranslation('forms')

  const buttonText = text || t('defaultSubmitButtonLabel')
  return (
    <Container>
      <StyledButton
        type="submit"
        appearance={'badgeBlack'}
        size={'L'}
        disabled={disabled}
        invalid={Boolean(invalid && helpText)}
      >
        {loading ? <Loader /> : buttonText}
      </StyledButton>
      <HelpText invalid={invalid} success={success}>
        {helpText}
      </HelpText>
    </Container>
  )
}
