import { styled } from '@aether/styles'
import { FocusEvent, forwardRef, useEffect, useState } from 'react'
import { TextFieldPropsType } from './TextFieldPropsType'

const FieldContainer = styled('div', {
  width: '100%',
})

const InputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  $aetherFont: 'ui01',
})

const Input = styled('input', {
  margin: 0,
  $aetherFont: 'body01',
  borderRadius: '$rMax',
  display: 'block',
  height: '$buttonHeightL',
  padding: '0 32px',
  border: '1px solid',
  borderColor: '$black',
  color: '$black',
  appearance: 'none',
  '&::placeholder': {
    color: '$gray',
  },
  '&:focus': {
    outline: '$blue solid 3px',
    outlineOffset: '2px',
  },
  variants: {
    multiline: {
      true: {
        resize: 'none',
      },
    },
    isActive: {
      true: {
        '&::placeholder': {
          opacity: 1,
          transition: 'opacity 200ms cubic-bezier(0.6, 0.04, 0.98, 0.335) 10ms',
        },
      },
      false: {
        '&::placeholder': {
          opacity: 0,
          transition: 'none',
        },
      },
    },
    isInvalid: {
      true: {
        borderColor: '$red',
        color: '$red',
        '&:focus': {
          boxShadow: '0 0 0 3px $colors$red',
        },
      },
    },
    isDisabled: {
      true: {
        backgroundColor: '$gray_light',
      },
      false: {
        backgroundColor: '$white',
      },
    },
  },
})

const Label = styled('label', {
  zIndex: 2,
  margin: 0,
  border: 0,
  top: 0,
  left: 0,
  transition: 'transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  transformOrigin: 'left top',
  cursor: 'text',
  pointerEvents: 'none',
  color: '$gray',
  position: 'absolute',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  backgroundColor: '$white',
  padding: '2px 8px',
  variants: {
    isActive: {
      true: {
        transform: 'translate(27px, -6px) scale(0.80)',
      },
      false: {
        transform: 'translate(27px, 17px) scale(1)',
      },
    },
    isInvalid: {
      true: {
        color: '$red',
      },
    },
  },
})

const HelpText = styled('div', {
  position: 'relative',
  $aetherFont: 'body03',
  paddingTop: '$8',
  display: 'flex',
  alignItems: 'center',
  variants: {
    isInvalid: {
      true: {
        color: '$red',
      },
    },
  },
})

export const TextField = forwardRef<HTMLInputElement, TextFieldPropsType>(
  (props, ref) => {
    const {
      autoComplete,
      disabled,
      id,
      children,
      invalid,
      helpText,
      label,
      readOnly,
      onBlur,
      onFocus,
      value,
      type = 'text',
      style,
      className,
      placeholder,
      multiline,
      ...rest
    } = props

    const isValueSet = (value?: string): boolean =>
      typeof value === 'string' && value.length > 0

    const helpId = helpText ? `${id}__hint` : undefined

    const [isActive, setIsActive] = useState<boolean>(() => isValueSet(value))

    const handleOnBlur = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setIsActive(isValueSet(event.target.value))
      if (typeof onBlur === 'function') {
        onBlur(event)
      }
    }
    const handleOnFocus = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setIsActive(true)
      if (typeof onBlur === 'function') {
        onBlur(event)
      }
    }

    useEffect(() => {
      if (isValueSet(value)) {
        setIsActive(true)
      }
    }, [value])

    const htmlTag = multiline ? 'textarea' : 'input'

    return (
      <FieldContainer className={className}>
        <InputContainer>
          {label && (
            <Label htmlFor={id} isInvalid={invalid} isActive={isActive}>
              {label}
            </Label>
          )}

          <Input
            {...rest}
            aria-describedby={helpId}
            aria-errormessage={invalid && helpId ? helpId : undefined}
            aria-invalid={invalid}
            disabled={disabled}
            id={id}
            readOnly={readOnly}
            ref={ref}
            type={type}
            autoComplete={autoComplete}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            isInvalid={invalid}
            isDisabled={disabled}
            isActive={isActive}
            value={value}
            placeholder={placeholder}
            multiline={multiline}
            as={htmlTag}
          />
        </InputContainer>
        {helpText ? (
          <HelpText id={helpId} isInvalid={invalid}>
            {helpText}
          </HelpText>
        ) : null}
      </FieldContainer>
    )
  },
)
