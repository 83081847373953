import { FC } from 'react'
import { SectionPortraitPictureWithCaptionProps } from '@aether/models'
import { Container, Link, SectionWrapper, MediaLink } from '@aether/components'
import { styled } from '@aether/styles'
import { createDoubleLinksArray } from '@aether/utils'

const MediaWrap = styled('div', {
  variants: {
    imagePosition: {
      left: {
        gridArea: 'left',
      },
      right: {
        gridArea: 'left',
        '@lg': {
          gridArea: 'right',
        },
      },
    },
  },
})

const SmallMediaWrap = styled('div', {
  width: '100%',
  display: 'none',
  alignSelf: 'end',
  '@lg': {
    display: 'block',
  },
})
const StyledContainer = styled(Container, {
  display: 'grid',
  gridGap: '$32',
  width: '100%',
  height: '100%',

  gridTemplateAreas: `
                      'left'
                      'right'
                      `,
  '@lg': {
    gridTemplateAreas: `
    'left right'
    `,
  },
  variants: {
    imagePosition: {
      left: {
        '@lg': {
          gridTemplateColumns: '1.5fr 1fr',
        },
      },
      right: {
        '@lg': {
          gridTemplateColumns: '1fr 1.5fr',
        },
      },
    },
  },
})
const Title = styled('p', {
  $aetherFont: 'heading02',
  marginBottom: '- $12',
})
const ColumnWithText = styled('div', {
  display: 'grid',
  justifyItems: 'start',
  gridAutoFlow: 'row',
  gridAutoRows: 'min-content',
  gridTemplateRows: 'auto 1fr',
  gap: '$24',
  paddingLeft: '$12',
  borderLeft: '1px solid $black',
  '@lg': {
    paddingLeft: 0,
    borderLeft: 0,
  },
  variants: {
    imagePosition: {
      left: {
        gridArea: 'right',
        paddingLeft: '$12',
        borderLeft: '1px solid $black',
        '@lg': {
          borderLeft: 0,
          paddingLeft: 0,
          paddingRight: '$32',
          borderRight: '1px solid $black',
        },
      },
      right: {
        paddingLeft: '$12',
        gridArea: 'right',
        '@lg': {
          gridArea: 'left',
          paddingLeft: '$32',
          borderLeft: '1px solid $black',
        },
      },
    },
  },
})

const TextWrap = styled('div', {
  display: 'grid',
  gap: '$16',
  justifyItems: 'start',
})

const StyledText = styled('p', {
  $aetherFont: 'body02',
})

export const SectionPortraitPictureWithCaption: FC<
  SectionPortraitPictureWithCaptionProps
> = ({
  title,
  image,
  smallImage,
  sectionSpaceSize,
  imagePosition,
  ctaLabel,
  ctaLink,
  secondaryCtaLabel,
  secondaryCtaLink,
  text,
}) => {
  const links = createDoubleLinksArray(
    ctaLink,
    ctaLabel,
    secondaryCtaLink,
    secondaryCtaLabel,
  )

  return (
    <SectionWrapper>
      <StyledContainer
        spaceSize={sectionSpaceSize}
        imagePosition={imagePosition}
      >
        <MediaWrap imagePosition={imagePosition}>
          {image && (
            <MediaLink
              media={{
                ...image,
                hardcropRatio: 'portrait1',
                sizes: { lg: '50vw' },
              }}
              link={links.length === 1 ? links[0].ctaLink : undefined}
            />
          )}
        </MediaWrap>
        <ColumnWithText imagePosition={imagePosition}>
          <TextWrap>
            {title && <Title>{title}</Title>}
            {text && <StyledText>{text}</StyledText>}
            {links.length > 0 &&
              links.map(({ ctaLink, ctaLabel }, index) => (
                <Link
                  {...ctaLink}
                  appearance="primary"
                  key={`${ctaLabel}-${index}`}
                >
                  {ctaLabel}
                </Link>
              ))}
          </TextWrap>

          {smallImage && (
            <SmallMediaWrap>
              <MediaLink
                media={{
                  ...smallImage,
                  hardcropRatio: 'landscape1',
                  sizes: { lg: '50vw' },
                }}
                link={links.length === 1 ? links[0].ctaLink : undefined}
              />
            </SmallMediaWrap>
          )}
        </ColumnWithText>
      </StyledContainer>
    </SectionWrapper>
  )
}
