import { forwardRef } from 'react'
import { styled } from '@aether/styles'
import { CheckboxFieldPropsType } from './CheckboxFieldPropsType'

const FieldContainer = styled('div', {
  width: '100%',
})

const Input = styled('input', {
  position: 'absolute',
  width: 1,
  height: 1,
  opacity: 0,
})

const CheckMark = styled('div', {
  marginRight: '$16',
  position: 'relative',
  border: '2px solid black',
  borderRadius: '$rMax',
  width: '32px',
  height: '32px',
  '&::after': {
    content: '',
    position: 'absolute',
    borderRadius: '$rMax',
    backgroundColor: '$black',
    width: '24px',
    height: '24px',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    transition: 'opacity 150ms ease-in',
  },
  variants: {
    isInvalid: {
      true: {
        borderColor: '$red',
      },
    },
    checked: {
      true: {
        '&::after': {
          opacity: 1,
        },
      },
      false: {
        '&::after': {
          opacity: 0,
        },
      },
    },
  },
})

const Label = styled('label', {
  $aetherFont: 'ui05',
  display: 'inline-flex',
  alignItems: 'center',
  margin: '0 16px 0 0',
  cursor: 'pointer',
  [`${Input}:focus + ${CheckMark}`]: {
    outline: '$blue solid 3px',
    outlineOffset: '2px',
  },
  variants: {
    isInvalid: {
      true: {
        color: '$red',
        [`${Input}:focus + ${CheckMark}`]: {
          outline: '$red solid 3px',
          outlineOffset: '2px',
        },
      },
    },
  },
})

const HelpText = styled('div', {
  position: 'relative',
  $aetherFont: 'body03',
  minHeight: '22px',
  display: 'flex',
  alignItems: 'center',
  variants: {
    isInvalid: {
      true: {
        color: '$red',
      },
    },
  },
})

export const CheckboxField = forwardRef<
  HTMLInputElement,
  CheckboxFieldPropsType
>((props, ref) => {
  const {
    label,
    onChange,
    isChecked,
    value,
    className,
    helpText,
    id,
    invalid,
    ...rest
  } = props

  const helpId = helpText ? `${id}__hint` : undefined

  return (
    <FieldContainer className={className}>
      <Label isInvalid={invalid}>
        <Input
          {...rest}
          aria-describedby={helpId}
          aria-errormessage={invalid && helpId ? helpId : undefined}
          aria-invalid={invalid}
          type="checkbox"
          onChange={onChange}
          value={value}
          ref={ref}
        />
        <CheckMark isInvalid={invalid} checked={Boolean(value)} />
        {label}
      </Label>
      {helpText ? (
        <HelpText id={helpId} isInvalid={invalid}>
          {helpText}
        </HelpText>
      ) : null}
    </FieldContainer>
  )
})
