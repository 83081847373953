import { styled } from '@aether/styles'
import { FC } from 'react'
import { OptionValueProps } from './ProductOptionGroup'
import { useTranslation } from 'react-i18next'
const Tooltip = styled('span', {
  $aetherFont: 'ui07',
  color: '$white',
  backgroundColor: '$black',
  borderRadius: '2px',
  padding: '1px $4',
  position: 'absolute',
  top: '-$20',
  left: '50%',
  transform: 'translateX(-50%) translateY(6px)',
  width: 'fit-content',
  height: '15px',
  pointerEvents: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0,
  transition: 'opacity 300ms ease-out, transform 300ms ease-out',
  lineHeight: 'normal',
})
const Root = styled('div', {
  display: 'grid',
  position: 'relative',
  background: 'none',
  padding: '$0',
  margin: '10px 0 0 0',

  '&:hover': {
    [`${Tooltip}`]: {
      '&[data-show="true"]': {
        opacity: 1,
        transform: 'translateX(-50%) translateY(0)',
      },
    },
  },
})
const LabelWrap = styled('label', {
  display: 'inline-flex',
  $aetherFont: 'ui02',
  lineHeight: '22px',
  borderRadius: '$r1',
  // Button size should be uniform 54x32px.
  minHeight: 32,
  minWidth: 54,
  padding: '$4 $12',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: '1px solid transparent',
  transition: 'background-color 250ms, color 250ms',
  overflow: 'hidden',
  svg: { display: 'none' },
  variants: {
    selected: {
      // Selected is fill
      true: {
        border: '1px solid $black',
        backgroundColor: '$black',
        color: '$white',
      },
      // Unselected is black outline
      false: {
        backgroundColor: '$white',
        color: '$black',
        border: '1px solid $black',
      },
    },
    disabled: {
      // sold out is grey
      true: {
        backgroundColor: '$gray_light_third',
        border: '1px solid $gray_medium_light',
        color: '$gray_medium',
        '&:hover': {
          border: '1px solid $black',
        },
      },
    },
    false: {
      '&:hover': {
        backgroundColor: '$black',
        color: '$white',
      },
    },
  },
})

const InputRadio = styled('input', {
  appearance: 'none',
  border: 0,
  position: 'absolute',
})

export const ProductOptionValue: FC<
  OptionValueProps & {
    selected?: boolean
    disabled?: boolean
    onChange?: (id: string, groupId: string) => void
    isLowStock?: boolean
    productTitle?: string
  }
> = ({
  id,
  onChange,
  optionId,
  selected,
  title,
  disabled,
  isLowStock,
  productTitle,
}) => {
  const { t } = useTranslation('product')
  const soldOutLabelTitle = t('soldOutLowerCase')

  const mixedId = `${optionId}_${id}`

  return (
    <Root>
      <InputRadio
        type="radio"
        id={mixedId}
        aria-label={
          disabled
            ? `${productTitle}, ${title} not available. Select to join the waiting list.`
            : `${title} ${isLowStock ? t('lowStock') : ''}`
        }
        required
        name={optionId}
        value={id}
        onChange={() => (onChange ? onChange(id, optionId) : null)}
        checked={selected}
        aria-describedby={disabled ? `tooltip-${mixedId}` : undefined}
      ></InputRadio>

      <Tooltip
        role="tooltip"
        id={`tooltip-${mixedId}`}
        data-show={disabled && !selected}
      >
        {t('waitlist')}
      </Tooltip>

      <LabelWrap
        selected={selected}
        disabled={disabled}
        htmlFor={mixedId}
        title={disabled ? soldOutLabelTitle : undefined}
      >
        {title}
      </LabelWrap>
    </Root>
  )
}
