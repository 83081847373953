import { FC } from 'react'
import { JournalPictogram } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import { Container, Link, Pictogram } from '@aether/components'
import { useTranslation } from 'react-i18next'

const HEADER_TRANSITION_DURATION = 150

const Content = styled(Container, {
  flex: 1,
  display: 'grid',
  gridAutoColumns: '1fr auto',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

const ScrollIndicatorContainer = styled('div', {
  width: '100%',
  height: '6px',
  background: '$beige',
})

const ProgressBar = styled('div', {
  height: '6px',
  background: '$blue',
  width: '0%',
  borderRadius: '0px 25px 25px 0px',
  transition: 'width 50ms linear',
})

const TitleContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridColumn: '1',
  gridRow: '1',
})

const Title = styled('h2', {
  gridColumn: '2',
  gridRow: '1',
  $aetherFont: 'heading06',
  display: 'inline-block',
  marginRight: '$40',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  '@lg': {
    $aetherFont: 'heading04',
    '&:before': {
      content: '•',
      marginRight: '$16',
      marginLeft: '$16',
    },
  },
})

const StyledPictogram = styled(Pictogram, {
  gridColumn: '1',
  gridRow: '1',
})

const LinkWrap = styled('div', {
  gridColumn: '2',
  gridRow: '1',
})

const Wrap = styled('div', {
  justifyContent: 'space-between',
  backgroundColor: '$beige',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
  transition: `opacity ${HEADER_TRANSITION_DURATION}ms`,
  transform: 'translateY(0)',
  top: '$postSubheaderHeightNormal',
  '@lg': {
    top: '$postSubheaderHeightLarge',
  },
})

type JournalPostHeaderProps = {
  pictogram?: JournalPictogram
  title?: string
  scrollPercent: number
  hideShopTheProducts?: boolean
}

export const JournalPostHeader: FC<JournalPostHeaderProps> = ({
  pictogram,
  title,
  scrollPercent,
  hideShopTheProducts = false,
}) => {
  const isLg = useMediaQuery('lg')
  const { t } = useTranslation('journal')

  return (
    <Wrap>
      <Content>
        <TitleContainer>
          {isLg ? <StyledPictogram pictogram={pictogram} size="S" /> : null}
          <Title>{title}</Title>
        </TitleContainer>
        {!hideShopTheProducts && (
          <LinkWrap>
            <Link
              appearance="primary"
              action={{ type: 'productSectionLinkAction' }}
            >
              {isLg ? t('shopStoryDesktop') : t('shopStoryMobile')}
            </Link>
          </LinkWrap>
        )}
      </Content>
      <ScrollIndicatorContainer>
        <ProgressBar css={{ width: `${scrollPercent}%` }} />
      </ScrollIndicatorContainer>
    </Wrap>
  )
}
