import { FC } from 'react'
import { SectionCategoryPostsPreviewProps } from '@aether/models'
import {
  AccordionItem,
  AccordionItemsList,
  AccordionHeaderDefault,
  Container,
  Link,
  SectionWrapper,
} from '@aether/components'
import { styled } from '@aether/styles'
import { PostsGallery } from './PostsGallery'

const CollectionTitleContainer = styled('article', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  paddingRight: '$8',
  flexDirection: 'column',
  alignItems: 'start',
  ':first-child': {
    marginBottom: '$12',
  },
  '@md': {
    flexDirection: 'row',
    ':first-child': {
      marginBottom: '0px',
      marginRight: '12px',
    },
  },
})

const Root = styled(Container, {
  variants: {
    pageContext: {
      journal: {
        backgroundColor: '$beige',
      },
      default: {
        backgroundColor: '$white',
      },
    },
  },
  paddingTop: '$sectionSpaceMobile',
  paddingBottom: '$sectionSpaceMobile',
  '@md': {
    paddingTop: '$sectionSpaceDesktop',
    paddingBottom: '$sectionSpaceDesktop',
  },
})

const StyledPostsGallery = styled(PostsGallery, {
  paddingBottom: '$32',
  '@md': {
    paddingBottom: '$56',
  },
  variants: {
    isLast: {
      true: {
        paddingBottom: '$0',
      },
    },
  },
})

export const SectionCategoryPostsPreview: FC<
  SectionCategoryPostsPreviewProps
> = ({ categories, pageContext = 'default' }) => {
  const allCategories = categories || []
  const allCategoriesWithPosts = allCategories.filter(
    ({ postsCount }) => postsCount > 0,
  )

  return (
    <SectionWrapper withBackground={pageContext === 'journal'}>
      <Root pageContext={pageContext} spaceSize="none">
        <AccordionItemsList
          allowMultipleActiveItems={true}
          initiallyOpenedItemsIds={[]}
          accordionId={'postCategories'}
        >
          {(handleRequestOpen, handleRequestClose, isActive, accordionId) => {
            return allCategoriesWithPosts.map(
              (
                { posts, title, path: collectionPath, postsCount },
                itemIndex,
              ) => {
                const itemId = `${accordionId}-${itemIndex}`
                return (
                  <AccordionItem
                    key={itemId}
                    headerType="default"
                    isActive={isActive(itemId)}
                    label={title || ''}
                    itemId={itemId}
                    onRequestOpen={handleRequestOpen}
                    onRequestClose={handleRequestClose}
                    headerComponent={({ isActive }) => (
                      <Container>
                        <AccordionHeaderDefault isActive={isActive}>
                          <CollectionTitleContainer>
                            <span>{title}</span>
                            {isActive && (
                              <Link href={collectionPath} appearance="primary">
                                View All {postsCount} posts
                              </Link>
                            )}
                          </CollectionTitleContainer>
                        </AccordionHeaderDefault>
                      </Container>
                    )}
                  >
                    <StyledPostsGallery
                      posts={posts}
                      isLast={allCategoriesWithPosts.length === itemIndex + 1}
                    />
                  </AccordionItem>
                )
              },
            )
          }}
        </AccordionItemsList>
      </Root>
    </SectionWrapper>
  )
}
