import { FC } from 'react'
import { styled } from '@aether/styles'
import { Link } from '@aether/components'
import { ROUTE_BASE_COLLECTIONS } from '@aether/configs'
import { ShopifyCollectionRes } from '@aether/models'

const BreadcrumbNav = styled('nav', {
  display: 'flex',
})

const List = styled('ol', {
  display: 'flex',
  listStyle: 'none',
})

const Divider = styled('span', {
  display: 'inline-block',
  margin: '0 $4',
  $aetherFont: 'ui03',
})

const StyledLink = styled(Link, {
  $aetherFont: 'ui03',
  padding: '0',
})

type BreadcrumbsType = {
  breadcrumbs?: ShopifyCollectionRes[]
}

export const ProductBreadcrumbs: FC<BreadcrumbsType> = ({ breadcrumbs }) => {
  if (!breadcrumbs) return null
  const filteredBreadcrumbs = breadcrumbs.filter(Boolean)
  if (!filteredBreadcrumbs.length) return null

  return (
    <BreadcrumbNav aria-label="Breadcrumb">
      <List>
        {filteredBreadcrumbs.map((breadcrumb, i) => {
          const isLast = i === filteredBreadcrumbs.length - 1
          return (
            <li key={breadcrumb.title}>
              {i !== 0 && (
                <Divider aria-hidden={true} role="presentation">
                  /
                </Divider>
              )}
              <StyledLink
                href={`${ROUTE_BASE_COLLECTIONS}/${breadcrumb.handle}`}
                appearance="defaultLink"
                aria-current={isLast ? 'page' : undefined}
              >
                {breadcrumb?.breadcrumbTitle?.value || breadcrumb.title}
              </StyledLink>
            </li>
          )
        })}
      </List>
    </BreadcrumbNav>
  )
}
